@import "react-slideshow-image/dist/styles.css";

@font-face {
  font-family: "regular";
  src: url("./components/assets/fonts/IRANSansRegular.ttf");
}

@font-face {
  font-family: "navbar";
  src: url("./components/assets/fonts/Yekan.ttf");
}
@font-face {
  font-family: "englishText";
  src: url("./components/assets/fonts/InterVariable.ttf");
}

:root {
  --ourBlue: #334456;
  --ourRed: #e31228;
  --ourGreen: #00755e;
}
/* --ourGreen: #8cc73c; */

.App {
  text-align: center;
  direction: rtl;
  background-color: #f2f2f5;
}

body {
  font-size: 18px;
}

* {
  font-size: 19px;
  font-family: regular, englishText;
  font-weight: normal;
}

div {
  text-align: center;
}

.loginButtons {
  max-width: fit-content;
  width: 50%;
  background-color: var(--ourGreen);
  font-size: 25px;
  cursor: pointer;
  letter-spacing: 2px;
  font-weight: 400;
  color: white;
  border: none;
  border-radius: 35px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  display: inline;
  /* padding: 70px; */
}

.loginButtons:hover {
  background-color: var(--ourGreen);
  box-shadow: 0px 15px 20px var(--ourGreen);
  transform: translateY(-7px);
}

.loadingGif {
  height: 200px;
}

/* ****************************** */

/* *************popup*************** */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 999;
}

/* Ensure the overlay is above other elements but below the popup */

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  border-radius: 10px;
  /* Ensure the popup is above other elements */
}

.popup p {
  margin: 20px;
}

.closeButton {
  position: absolute;
  top: -1px;
  right: -1px;
  background-color: red;
  color: white;
  font-size: 40px;
  line-height: 30px;
  height: 30px;
  width: 40px;
  text-align: center;
  border: none;
  /* border-radius: 7px; */
}

/* *************end of popup*************** */

/* *************navbar*************** */
.navbarFont {
  font-family: navbar;
  font-size: 20px !important;
  background-color: var(--ourBlue);
  color: white !important;
}

.navbarFontActive {
  font-family: navbar;
  font-size: 20px !important;
  background-color: white;
  color: var(--ourBlue) !important;
  border-radius: 10px;
}

#navbarLogo {
  border-radius: 50%;
  width: 75px;
  height: 70px;
  margin-left: 40px;
}

#nav-dropdown {
  color: white !important;
}

/* *************end of navbar*************** */

/* *************footer*************** */
.socials {
  border: solid 1px;
  margin: 5px;
  padding: 8px;
  border-radius: 5px;
  /* cursor: pointer; */
  color: white;
}

/* *************end of footer*************** */

/* *******************about********************** */
#aboutImg {
  width: 100%;
  height: 60vh;
  filter: brightness(40%);
}

#aboutText {
  position: absolute;
  top: 10vh;
  right: 5vw;
}
#aboutText button {
  margin: 20px;
  color: white;
  background-color: #0077b6;
  border-radius: 7px;
  border: none;
  padding: 10px;
  transition: all 0.3s ease 0s;
}
#aboutText button:hover {
  /* background-color: white;
  color: #0077b6; */
  box-shadow: 0px 10px 20px #0077b6;
  transform: translateY(-6px);
}
#aboutImgContainer {
  position: relative;
  text-align: center;
  color: white;
}

#aboutData {
  max-width: 80%;
  margin: 0 auto;
}

hr.half {
  width: 40%;
  margin-right: auto;
  margin-left: auto;
  border-top: 3px solid var(--ourBlue);
}

#aboutData h2 {
  padding-top: 20px;
  color: var(--ourBlue);
}

.serviceContain {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin: 20pt auto;
}

@media only screen and (max-width: 700px) {
  .serviceContain {
    display: grid;
    grid-template-columns: 1fr;
  }
}

.services {
  margin: 0rem 1rem 0.5rem;
  margin-bottom: 1rem;
  border-radius: 8px;
  height: 100%;
}
.services b {
  color: #0077b6;
}

.serviceImg {
  width: 100%;
  transition: all 0.4s ease;
  overflow: hidden;
  max-height: 260px;
  margin: 10px;
}

.serviceImg:hover {
  transform: scale(1.05);
}

#contactContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.flex {
  display: flex;
  margin: 20pt;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.flex > div {
  margin: 0 1rem 1rem 0.5rem;
  text-align: center;
}

/* *******************end of about********************** */

/* *******************first page********************** */
#firstPageSlider {
  background-image: url("./components/assets/slider/sliderBackground.jpg");
  background-size: 100% 100%;
  padding-top: 60px;
  padding-bottom: 10px;
  /* background-color: rgba(255, 255, 255, 0.2); */
  /* background-blend-mode: color; */
  margin-bottom: 40px;
}

.parts > * {
  margin: 0 auto;
}

.parts > h3 {
  color: var(--ourBlue);
  /* position: relative; */
}

#marketPulse {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 20px;
}

@media only screen and (max-width: 900px) {
  #marketPulse {
    grid-template-columns: 1fr;
  }
}

#marketPulseTable {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  /* border: 1px solid black; */
  width: 100%;
}

#marketPulseTable th {
  background-color: #787070;
  color: white;
  font-weight: bold;
}
#athAndBubble {
  display: grid;
  grid-template-columns: 2fr 1fr;
}
.servicesInFirstPage {
  display: grid;
  grid-template-columns: 1fr 1fr;
  color: var(--ourBlue);
  padding: 30px;
  margin: 50px;
  width: 90%;
  border-radius: 8px;
  transition: transform 0.5s ease-in-out;
}
@media only screen and (max-width: 900px) {
  .servicesInFirstPage {
    grid-template-columns: 1fr;
  }
  #athAndBubble {
    grid-template-columns: 1fr;
  }
}
.servicesInFirstPage:hover {
  transform: scale(1.05);
}
.servicesInFirstPage h3 {
  text-align: right;
}
.servicesInFirstPage p {
  font-weight: bold;
  text-align: justify;
  padding: 30px;
}

.servicesInFirstPage button {
  border-radius: 7px;
  color: white;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  border: none;
  /* animation: beat 2s infinite; */
}
.servicesInFirstPage img {
  width: 100%;
  padding: 20px;
}
/* @keyframes beat {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
}*/

/* *******************end of first page********************** */

/* *******************bubble********************** */
#bubbleContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
#bubbleForm {
  background-image: url("./components/assets/bubble1.jpg");
  background-size: 100% 100%;
  background-color: rgba(255, 255, 255, 0.8);
  background-blend-mode: color;
  text-align: center;
  display: block;
  /* background-color: white; */
  color: black;
  padding: 40px;
  margin: 30px;
  font-weight: bold;
  border-radius: 10px;
  border-style: outset;
  width: fit-content;
}

#bubbleForm > label {
  display: block;
  text-align: right;
  margin: 15px;
  /* width: 100%; */
}

#submitSekke {
  max-width: 50%;
  width: 50%;
  background-color: #027ea1;
  font-size: 25px;
  cursor: pointer;
  letter-spacing: 2.5px;
  font-weight: 500;
  color: white;
  border: none;
  border-radius: 45px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  display: inline;
}

.bubbleInput {
  margin: 10px;
}

/* *******************end of bubble********************** */

/* *******************login********************** */
#formLogin {
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* width: 80%; */
  place-items: center;
  padding: 15px;
}

@media only screen and (max-width: 900px) {
  #formLogin {
    grid-template-columns: 1fr;
  }
}

#loginImage {
  width: 100%;
}

#formLogin label {
  text-align: start;
  display: block;
  width: 100%;
}

#formLogin input,
#formLogin select {
  border-radius: 15px;
  margin: 10px;
  padding: 5px;
  height: 35px;
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
}

#formLogin input[type="text"],
input[type="password"] {
  background: #ebedec;
  border: 1px solid #b0b0b0;
}

.changeLoginButtons {
  color: white;
  background-color: var(--ourBlue);
  border-radius: 7px;
  padding: 5px;
  cursor: pointer;
}

/* *******************end of login********************** */

/* *******************dashboard********************** */
.dashApp {
  display: flex;
}

#profileSidebar {
  /* height: 90vh; */
  /* overflow: scroll initial; */
  position: fixed;
  top: 95px;
  right: 0;
  overflow: hidden !important;
}

.activeClicked * {
  color: black;
  pointer-events: none;
  cursor: pointer;
  border-radius: 5px;
}

.activeClicked {
  opacity: 1;
  font-weight: bold;
}

.disabled-item {
  pointer-events: none;
  /*prevent any mouse interaction */
  opacity: 0.5;
  /*make it semi-transparent */
  cursor: not-allowed;
}

.content {
  padding: 60px;
  text-align: center;
  overflow-x: auto;
}

.content > * {
  margin-left: auto;
  margin-right: auto;
}

/* *******************initial dashboard********************** */
#dashboardProfile {
  background-color: gray;
  width: 100%;
  color: white;
  margin-bottom: 30px;
  border-collapse: collapse;
  border-radius: 8px;
}

.servicesDashboard {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin: 10px;
}

.servicesDashboard > button {
  border: 1px solid #b0b0b0;
  border-radius: 5px;
  margin: 20px;
  padding: 10px;
  color: white;
  font-weight: bold;
}

.servicesDashboard > button:hover {
  transform: scale(1.1);
}

@media only screen and (max-width: 800px) {
  .servicesDashboard {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

/* *******************initial dashboard********************** */

/* *******************common table********************** */
.tablesContainer {
  height: 550px;
  overflow-y: auto;
  width: 80%;
  margin-right: auto;
  margin-left: auto;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  /* border: 1px solid var(--ourBlue); */
}

.commonTable {
  color: white;
  width: 100%;
  /* overflow: hidden; */
}

.commonTable thead {
  position: sticky;
  top: -3px;
  /* border: 1px solid black; */
}

.commonTable th {
  position: sticky;
  top: -3px;
  background-color: #f2f2f2;
  color: #000;
  font-weight: bold;
  font-size: 19px;
}

.commonTable th,
.commonTable td {
  padding: 10px;
  direction: initial;
}

.commonTable tr {
  border: 1px solid black;
}
.commonTable tr:nth-child(even) {
  background-color: #dcdcdc; /* Change the background color to your desired color */
}
.commonTable tr:nth-child(odd) {
  background-color: white; /* Change the background color to your desired color */
}
.commonTable tr:hover {
  background-color: rgb(154, 186, 246);
}
/* *******************end of common table*************** */

/* *******************ath********************** */
#athTable th {
  background-color: purple;
  color: white;
}
/* *******************end of ath********************** */

/* *******************predict********************** */
#predictTable th {
  background-color: #334456;
  color: white;
}
#predictTable {
  color: black;
}
#predictButtonsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
#predictButtonsContainer button {
  color: var(--ourBlue);
  background-color: white;
  font-weight: bold;
  padding: 10px;
  margin: 20px;
  border-radius: 8px;
  border: 4px solid var(--ourBlue);
  font-size: larger;
}
.predictButtonActive {
  color: white !important;
  background-color: var(--ourBlue) !important;
}
/* *******************end of predict********************** */

/* *******************calendar********************** */
.customCalendarContainer {
  z-index: 2;
  position: absolute;
  margin-top: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
}
.custom-calendar {
  height: 450px !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.custom-calendar * {
  font-size: 18px !important;
}
/* *******************end of calendar********************** */

/* *******************board********************** */
#boardTable th {
  background-color: #e31228;
  color: white;
}
/* *******************end of board********************** */

/* *******************board filter********************** */

#boardFilter {
  background-color: white;
  padding: 20px;
  text-align: right;
  border-radius: 7px;
}
#boardFilter > div {
  display: inline;
}
#boardFilter label {
  margin: 20px;
  font-weight: bold;
}
#boardFilter > button {
  margin: 20px;
  background-color: white;
  border-radius: 7px;
}
/* *******************end of board filter********************** */

/* *******************fundamental********************** */
#fundamentalTable {
  color: black;
}
#fundamentalTable th {
  background-color: #00755e;
  color: white;
}
#fundamentalButtons {
  margin: 10px;
}
#fundamentalButtons button {
  padding: 10px;
  background-color: orange;
  border: none;
}

#fundamentalHelp p {
  display: inline;
  margin: 5px;
  margin-left: 15px;
  color: gray;
  font-size: medium;
}
#fundamentalHelp div {
  display: inline;
  text-align: right;
  margin: 5px;
}
#fundamentalHelp {
  width: 80%;
  margin-right: auto;
  margin-left: auto;
}
/* *******************end of fundamental********************** */

/* *******************idehban********************** */
.list-container {
  /* position: relative; */
  /*float: right;
  background-color: white; */
  margin-left: auto;
  margin-right: auto;
  width: 250px;
}
.list {
  list-style-type: none;
  position: absolute;
  z-index: 2;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  width: 250px;
  text-align: right;
  max-height: 200px;
  overflow-y: auto;
  border: solid 1px gray;
  border-top: none;
  border-bottom: none;
}
.list li {
  padding: 5px;
  border-bottom: solid 1px black;
}
.list li:hover {
  cursor: pointer;
}
#detailsPage {
  text-align: right;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
}
#detailsPage > div {
  text-align: right;
}
#detailsPage h2 {
  margin: 20px;
}
#detailsPage .tablesContainer {
  height: fit-content;
}

#detailsPagePredict {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 700px) {
  #detailsPagePredict {
    display: grid;
    grid-template-columns: 1fr;
  }
}
/* *******************end of didehban********************** */

/* *******************end of dashboard********************** */
.mobile-navbar {
  position: fixed;
  top: 95px;
  width: 100%;
  background: white;
  color: black;
  padding: 10px;
  /* padding-left: 30px; */
  display: flex;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  justify-content: flex-start;
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.mobile-navbar::-webkit-scrollbar {
  display: none;
}

.mobile-navbar::after {
  content: ">";
  font-size: 24px;
  color: #aaa;
  position: fixed;
  left: 0px;
  top: 120px;
  transform: translateY(-50%);
  background: white;
  z-index: 1001;
  }

.nav-list {
  display: flex;
  justify-content: space-around;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
}

.nav-list li>* {
  text-decoration: none;
  padding: 9px 10px;
  font-size: 14px;
  transition: all 0.3s ease-in-out;
}

.nav-list li>*:hover {
  color: #f0a500;
}

.active {
  font-weight: bold;
  color: #f0a500;
  border-bottom: 2px solid #f0a500;
}
